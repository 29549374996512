import React from 'react';
import { Route, Routes } from "react-router-dom";
import EventDetails from './pages/EventDetail';


const Footer = React.lazy(()=> import('./components/Footer'));
const VisionMission = React.lazy(()=> import('./pages/VisionMission'));
const Director = React.lazy(()=> import('./pages/Director'));
const About = React.lazy(()=> import('./pages/About'));
const Home = React.lazy(()=> import('./pages/Home'));
const Navbar = React.lazy(()=> import('./components/Navbar'));
const Message = React.lazy(()=> import('./pages/Message'));
const ExpertTeachers = React.lazy(()=> import('./pages/ExpertTeachers'));
const Transport = React.lazy(()=> import('./pages/Transport'));
const Classroom = React.lazy(()=> import('./pages/Classroom'));
const Playground = React.lazy(()=> import('./pages/Playground'));
const Labs = React.lazy(()=> import('./pages/Labs'));
const CoCurriculum = React.lazy(()=> import('./pages/CoCurriculum'));
const Event = React.lazy(()=> import('./pages/Events'))

function App() {
  return (
   <>
     <Navbar/>
     <Routes>
      
      <Route exact path="/" element={<Home />} ></Route>
      <Route exact path="/about-us" element={<About />} ></Route>
      <Route exact path="/message-principal-director" element={<Director />} ></Route>
      <Route exact path="/mision-vision" element={<VisionMission />} ></Route>
      <Route exact path="/message-chairman-secretary" element={<Message />} ></Route>
      <Route exact path="/expert-teachers" element={<ExpertTeachers />} ></Route>
      <Route exact path="/labs" element={<Labs />} ></Route>
      <Route exact path="/playground" element={<Playground />} ></Route>
      <Route exact path="/transport" element={<Transport />} ></Route>
      <Route exact path="/classroom" element={<Classroom />} ></Route>
      <Route exact path="/co-curricular" element={<CoCurriculum />} ></Route>
      <Route exact path="/events" element={<Event />} ></Route>
      <Route exact path="/no-fire-event" element={<EventDetails />} ></Route>



     
    </Routes>
    <Footer/>
   </>
  );
}

export default App;
