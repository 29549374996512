import { useSinglePrismicDocument } from "@prismicio/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const EventDetails = () => {
  
  const [eventDetails] = useSinglePrismicDocument("eventdetails");
  const eventDetail = eventDetails?.data;
  const settings = {
    slidesToShow: 4,
    arrows: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
  };
    

  return (
    <section className="custom_func">
      <div className="custom_style2 p-8">
        <h1
          className="text-5xl  uppercase mt-4"
          style={{ fontFamily: "Sofia Sans, sans-serif" }}
        >
          {eventDetail?.titlename?.[0].text}
          </h1>
        <div class="mt-8">
            
          <Slider {...settings}>
         {eventDetail?.imagesevents?.map((element, i) => (
        
        <>
           <img className="w-full mr-2" src={element.imagedetails.url} key={i} alt="Loading"></img>
                </>
        ))}
       </Slider>

        </div>
        <div className=" py-16  text-justify">        
           
              <p className="rps_about_us_description " > {eventDetail?.eventdescription} </p>
            


         
        </div>
      </div>
    </section>
  );
};

export default EventDetails;
